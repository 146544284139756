@font-face {
  font-weight: normal;
  font-family: "HSBCIcon-Font";
  font-style: normal;
  src: url(./static/fonts/hsbc-icons/hsbcicon-font.eot) format("embedded-opentype"),
       url(./static/fonts/hsbc-icons/hsbcicon-font.woff) format("woff"),
       url(./static/fonts/hsbc-icons/hsbcicon-font.ttf) format("truetype");
}

@font-face {
  font-weight: normal;
  font-family: "HSBC-Universe";
  font-style: normal;
  src: url(./static/fonts/univers.400.woff) format("woff")
}

@font-face {
  font-weight: normal;
  font-family: "HSBC-Universe-Light";
  font-style: normal;
  src: url(./static/fonts/univers.300.woff) format("woff")
}

@font-face {
  font-weight: normal;
  font-family: "HSBC-Universe-Medium";
  font-style: normal;
  src: url(./static/fonts/univers.500.woff) format("woff")
}


.App {
  text-align: left;
  max-width: 1250px;
  margin: auto;
  padding: 0 15px;
  font-family: "HSBC-Universe", serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background: #253038; 
  scrollbar-width: thin;
  scrollbar-base-color: #E4E8EA;
  scrollbar-face-color: #3E505D;
  scrollbar-highlight-color: #3E505D;
  scrollbar-track-color: #E4E8EA;
  scrollbar-shadow-color: #3E505D;
  scrollbar-dark-shadow-color: #3E505D;
}


.mainContent{
  background: white;
  padding: 0 20px;
  padding-bottom: 3.75rem;
  max-width: 1250px;
  min-width: 1202px;
  min-height: 500px;
  margin: 0;
}

.mainContent1{
  padding-top: 60px;
}

.mainContentPWD{
  padding-top: 20px;
}

header{
  position:static;
}

header > div {
  max-width: 1250px;
}

.heading{
  padding: 100px 0 0 0;
  width: 100%;
  max-width: 1250px;
  z-index: 50;
}

.noNav{
  padding-top: 3.75rem;
}

.horizontalLabel{
  float: left;
  font-size: 14px;
  line-height: 40px;
  width: 100px;
  height: 40px;
}

.longLabel{
  width: 200px;
}


a{
  color: black;
}

nav > ul > li{
  list-style: none;
}

.importantNotesUL{
  margin: 15px 0 0;
  padding-inline-start: 20px;
  font-size: 14px;
}

.importantNotesUL li{
  margin: 12px 0;
}

.importantNotesOL{
  margin: 0;
  padding-inline-start: 20px;
  font-size: 14px;
}

.importantNotesOL li{
  padding: 2px 0;
}

.contactBox{
  border-left: 0.8px solid #929292;
  padding-left: 10px;
  min-height: 70px;
  display: inline-block;
  margin-bottom: 20px;
}

.contactInfo{
  padding-left: 10px;
  font-size: 12px;
  padding-bottom: 10px;
}

.tableRow{
  display: table-row;
}

.tableCell{
  display: table-cell;
}

.inline{
  float: left;
}



.heading > div{
  padding: 22px 24px;
}

.tips li{
  line-height: 25px;
}

.textField{
  padding-bottom: 20px;
}

.medium{
  font-weight: 500;
  font-size: 20px;
}

.loginBtn{
  line-height: 60px;
  position: relative;
  left: -40px;
}

.loginTitle{
  font-size: 26px;
  font-weight: 200;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: HSBC-Universe-Light, serif;
}

.loginField{
  height: 40px;
  font-size: 14px;
}



.leftBorder{
  border-left: 1px solid #D7D8D6;
  padding-left: 15px;
}

.separator{
  height: 0.3125rem;
  border: none;
  background: #253038;
  transition: margin 400ms ease;
  will-change: margin;
}

.paddingR{
  padding-right: 10px;
}

.collapsible{
  font-size: 14px;
  text-decoration: none;
}

.collapsible > input{
  width: 100%;
}


.content-wrapper{
  padding-left: 20px;
  top: -20px;
  position: relative;
  width: 1182px;
}

.empty{
  height: 0.1px;
}

.rightBtn{
  padding-right: 20px;
  float: right;
  padding-bottom: 15px;
}

::-webkit-scrollbar{
    width: 10px;
    background-color:#E4E8EA;
}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb{
  background-color: #3E505D;
}

div.rt-th{
  overflow: visible;
}

.last{
  margin-bottom: 50px;
}

.ReactTable .rt-table .rt-thead .rt-th{
  font-family: HSBC-Universe-Medium, serif;
  overflow: visible !important;
}

.MuiNativeSelect-selectMenu {
  color: black;
  background-color: blue;
}

footer{
  max-width: 1250px;
  min-width: 1250px;
}

input{
  box-sizing: border-box;
}

input:read-only {
  background-color:#F3F3F3;
  border-color: #767676;
}



div.modal_container > div {
  box-sizing: content-box;
  overflow: hidden;
}

div.modal_container > div > a {
  line-height: normal;
}

label[data-tag-id="@cdl/radio-label@1.3.0"] {
  font-size: 14px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.cdl-checkbox label span:first-of-type { 
    padding-right: 5px; 
    width: 15px;
  }

  .rt-td .cdl-checkbox label span:first-of-type { 
    width: 20px;
  }
}

button[data-tag-id="@cdl/button@1.2.3"] span {
  font-family: HSBC-Universe, serif;
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
  button[data-tag-id="@cdl/button@1.2.3"]:focus {
    outline: none;
  }
}

@media all and (-ms-high-contrast: none){
  .react-select__placeholder, .react-select__single-value { 
    padding-top: 8px; 
  }
}
